const texts = [
 {
  type: "Вступление",
  title:
   "Решение ITOGO создано для запуска  ECOM\u2011проектов отвечающих высоким требованиям рынка.",
  content:
   "<p>Чтобы создать лучшее решение, мы тщательно тестировали его в реальной интернет\u2011торговле, непрерывно совершенствуя каждый из элементов для достижения отличного результата. Платформа позволяет быстро запускать ECOM\u2011направления. С нами вы получаете инфраструктуру, сразу готовую к работе бизнеса.</p><p>Платформа подходит для замены уже работающих ECOM\u2011решений. Мы предоставляем современный функционал и подходы дающие конкурентные преимущества.</p>",
 },

 {
  type: "Преимущества",
  title: "Преимущества",
  content:
   "<p>Охватывает ECOM\u2011процессы компании со стороны фронт\u2011офиса и бэк\u2011офиса</p><p>Быстрый старт проекта и начало продаж</p><p>Создан на современных подходах в EСОМ</p><p>Соответствует требованиям импортозамещения</p><p>Любая часть продукта может быть адаптирована под задачи клиента</p><p>Внедряйте решение сразу либо частями, запуская проект согласно вашему плану</p>",
 },

 // ЦЕНЫ
 {
  type: "Тариф_1",
  title: "Лицензия на использование платформы",
  content:
   "<p>Стоимость рассчитывается индивидуально по запросу на sales@itogo.ru</p>  <p>В стоимость входит:<br>Развёртывание платформы, техническая поддержка и обновления на год. Единоразовая кастомизация внешнего вида платформы под клиента на этапе запуска. Помощь в выборе поставщика инфраструктуры.</p>",
  button: "Запросить стоимость",
  buttonType: "primary",
 },
 {
  type: "Тариф_2",
  title: "Лицензия с правом самостоятельно модифицировать платформу",
  content:
   "<p>В стоимость входит: Открытый программный код, документация, технический консалтинг.</p>Свяжитесь с нами, чтобы узнать цену",
  button: "Запросить стоимость",
  buttonType: "secondary",
 },
 {
  type: "Внимание",
  title: "Доработка функционала под клиента оценивается отдельно",
 },

 /* ПОДВАЛ */
 {
  type: "Подвал",
  title: "Все права защищены. 2023 г. ООО ИТОГО",
 },

 /* ДЕТАЛИ */

 { type: "Вкладка", tabTitle: /* "Состав фронт-офиса" */ "Возможности", textContent: "<p>Покупка «в два клика»</p><p>Личный кабинет для физических и юридических лиц</p><p>Адаптирован под разные устройства</p><p>Персональные и спецпредложения, баннеры, аксессуары, отзывы, промокоды, оптовые продажи</p><p>Программа лояльности, партнёрская программа для юр. лиц и самозанятых</p><p>Эквайринг, кредитование, выставление счетов юр. лицам</p><p>Логистика от СДЭК, Л-Пост и КСЭ</p><p>Избранное и сравнение товаров, поиск по каталогу</p><p>Конструктор страниц сайта</p><p>Фиды для продвижения в рекламных сетях, подготовлен к SEO</p>" },
 /* { type: "Вкладка", tabTitle: "Состав бэк-офиса", textContent: "<p>Бэк-офис состоит из улучшенных конфигураций 1С</p> <p>1С:КА — управление данными контрагентов, заказами, ценами, закупками, отгрузками и остатками на складах</p> <p>1C:PIM (1С:ЦКТ) — ведение продуктового каталога, настройка описания товаров и связанных с ними данных </p> <p>1С:Розница — управление промокодами, программой лояльности</p> <p>Заявки поступают в Service Desk Intraservice</p> <p>Intraservice — система учета заявок по обратной связи с сайта</p> <p>1C и IntraService в реестре Российского ПО</p>" }, */
 { type: "Вкладка", tabTitle: "Технологии", textContent: "<p>Современный стек разработки соответствует высоким требованиям безопасности, производительности и поддержки</p><p>Agular  ·  C#  ·  .NET Core<br>RabbitMQ  ·  PostgreSQL  ·  Redis<br>MinIO  ·  Kubernetes  ·  ELK<br>Prometheus  ·  Grafana</p><p>Масштабируемая архитектура, готовая к нагрузкам тысячи пользователей в секунду</p><p>Архитектура, созданная по принципам отказоустойчивости</p>" },
];

export default texts;
