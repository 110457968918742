import React from "react";

const PriceCard_1 = ({ texts }) => {
 return (
  <div className="price_card_container">
   {texts.map((texts, index) => (
    <div className="price_card" key={index}>
     <div className="price_card_title_and_text">
      <h3>{texts.title}</h3>
      <div
       className="text_2"
       dangerouslySetInnerHTML={{ __html: texts.content }}
      ></div>
     </div>
     <a href="mailto:sales@itogo.ru?subject=Запрос информации о платформе ITOGO" className="full_width_button">
     <button >
      <div className="text_title">{texts.button}</div>
     </button></a>
    </div>
   ))}
  </div>
 );
};

export default PriceCard_1;
