import React from "react";

class DocPopup extends React.Component {
 render() {
  return (
   <div className="doc_popup">
    <div className="doc_popup_close_pc">
     <img src="/images/modals_top.svg" alt="Закрыть"></img>
    </div>
    <div className="doc_popup_text_and_button">
     <div className="doc_popup_close">
      <img src="/images/close.svg" alt="Закрыть"></img>
     </div>
     <div className="doc_popup_text">
      <h2>
       Платформа ITOGO имеет исчерпывающею документацию по всем элементам системы
      </h2>
      <div className="text">
       Мы готовы ответить на дополнительные вопросы по работе платформы
      </div>
     </div>
     
     <a href="/doc/1_setup.pdf" target="_blank" className="full_width_button">
     <button>
      <div className="text_title">Установка</div>
     </button></a>
     <a href="/doc/2_functional.pdf" target="_blank" className="full_width_button">
     <button>
      <div className="text_title">Функционал</div>
     </button></a>
     <a href="/doc/3_lifecycle.pdf" target="_blank" className="full_width_button">
     <button>
      <div className="text_title">Жизненный цикл</div>
     </button></a>
     <a href="/doc/4_user_manual.pdf" target="_blank" className="full_width_button">
     <button>
      <div className="text_title">Руководство пользователя</div>
     </button></a>
     
    </div>
   </div>
  );
 }
}

export default DocPopup;
