import React, { useState } from "react";
import texts from "../texts.js"; // Импорт массива с данными

const Details = () => {
 const [activeTab, setActiveTab] = useState(0);

 const handleTabClick = (index) => {
  setActiveTab(index);
 };

 return (
  <div>
   <div className="tabs">
    {texts
     .filter((item) => item.type === "Вкладка")
     .map((item, index) => (
      <div
       key={index}
       className={`tab ${index === activeTab ? "active" : ""}`}
       onClick={() => handleTabClick(index)}
      >
       {item.tabTitle}
      </div>
     ))}
   </div>
   <div className="tab_content" dangerouslySetInnerHTML={{ __html: texts.filter((item) => item.type === "Вкладка")[activeTab]?.textContent }}></div>
   
   
  </div>
 );
};

export default Details;
