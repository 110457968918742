import React from "react";
import "../index.css";
import TextBlock from "../components/TextBlock.js";
import PriceCard_1 from "../components/PriceCard_1.js";
import PriceCard_2 from "../components/PriceCard_2.js";
import PriceAttention from "../components/PriceAttention.js";
import Footer from "../components/Footer.js";
import Details from "../components/Details.js";

import texts from "../texts.js";

class Home extends React.Component {
 handleMenuToggle = () => {
  this.setState((prevState) => ({
   isMenuVisible: !prevState.isMenuVisible,
  }));
 };

 render() {
  return (
   <div className="container">
    <div className="header">
     <img src="/images/logo.svg" alt="Logo" />
     <a href="/doc" className="documentation_button">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       width="26"
       height="24"
       viewBox="0 0 26 24"
       fill="none"
      >
       <path
        d="M14.0418 3C11.9133 3 9.41061 3 7.7074 3C6.60283 3 5.7085 3.89543 5.7085 5V19C5.7085 20.1046 6.60393 21 7.70849 21H18.2918C19.3964 21 20.2918 20.1072 20.2918 19.0026C20.2918 16.5625 20.2918 12.3464 20.2918 9M14.0418 3C16.1128 5.00841 18.2209 6.99159 20.2918 9M14.0418 3C14.0418 4.07139 14.0418 5.70714 14.0418 7.00024C14.0418 8.10481 14.9373 9 16.0418 9H20.2918"
        stroke="#5D6672"
        stroke-width="2"
        stroke-linecap="round"
       />
      </svg>
      <div className="text_2">Документация</div>
     </a>
    </div>
    <div className="main">
     <div className="main_title">
      <h2>ECOM-Платформа ITOGO</h2>
     </div>
     <div className="main_content">
      <div className="screens">
       <picture>
        <source srcset="/images/screens.webp" type="image/webp"></source>
        <source srcset="/images/screens.jpg" type="image/jpeg"></source>
        <img
         src="/images/screens.jpg"
         alt="Отображение на разных экранах"
        ></img>
       </picture>
      </div>

      <div className="main_text_and_prices">
       <div className="main_text">
        <TextBlock texts={texts.filter((item) => item.type === "Вступление")} />
        <TextBlock
         texts={texts.filter((item) => item.type === "Преимущества")}
        />
       </div>
       <div className="prices">
        <div className="price_first_and_attention">
         <PriceCard_1 texts={texts.filter((item) => item.type === "Тариф_1")} />
         <PriceAttention
          texts={texts.filter((item) => item.type === "Внимание")}
         />
        </div>
        {/* <div className="price_second">
         <PriceCard_2 texts={texts.filter((item) => item.type === "Тариф_2")} />
        </div> */}
       </div>
      </div>
     </div>
    </div>
    <div className="details">
     <Details texts={texts.filter((item) => item.type === "Вкладка")} />
    </div>
    <div className="footer_round_bg">
     <div className="footer_round"></div>
    </div>
    <div className="footer">
     <img src="/images/logo_dark.svg" alt="логотип"></img>
     <Footer texts={texts.filter((item) => item.type === "Подвал")} />
    </div>
   </div>
  );
 }
}

export default Home;
