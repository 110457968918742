import React from "react";

const Footer = ({ texts }) => {
 return (
  <div>
   {texts.map((texts, index) => (
    <div key={index}>
     <div className="text_2">{texts.title}</div>
    </div>
   ))}
  </div>
 );
};

export default Footer;
