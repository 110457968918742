import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";   

import Documentation from "./pages/Documentation";

function App() {
 return (
  <Router>
   <Routes>
    <Route path="/" element={<Home />} />
    <Route path="*" element={<Home />} />
    <Route path="/images*" element={<Home />} />

    <Route path="/doc" element={<Documentation />} />
    <Route path="/docs" element={<Documentation />} />
    <Route path="/documentation" element={<Documentation />} />

   </Routes>
  </Router>
 );
}

export default App;
