import React from "react";

const TextBlock = ({ texts }) => {
 return (
  <div className="main_text_blocks">
   {texts.map((texts, index) => (
    <div className="main_text_block" key={index}>
     <div className="text_title">{texts.title}</div>
     <div dangerouslySetInnerHTML={{ __html: texts.content }}></div>
    </div>
   ))}
  </div>
 );
};

export default TextBlock;
