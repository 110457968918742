import React from "react";
import Home from "./Home";
import DocPopup from "../components/DocPopup";

class Documentation extends React.Component {
 render() {
  return (
   <div>
    <a href="/">
     <div className="documentation_page">
      <DocPopup />
     </div>
    </a>
    <div className="home_page">
     <Home />
    </div>
   </div>
  );
 }
}

export default Documentation;
