import React from "react";

const PriceAttention = ({ texts }) => {
 return (
  <div>
   {texts.map((texts, index) => (
    <div className="price_attention" key={index}>
     <img
      src="/images/attention.svg"
      alt="иконка восклицательного знака"
     ></img>
     <div className="text_2">{texts.title}</div>
    </div>
   ))}
  </div>
 );
};

export default PriceAttention;
